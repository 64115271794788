import vue from 'vue'
import vueRouter from 'vue-router'
vue.use(vueRouter)

const originalPush = vueRouter.prototype.push
vueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new vueRouter({
    mode: 'hash',
    routes: [{
        path: "/",
        name: "login",
        component: () =>
            import ('../page/login/index.vue')
    }, {
        path: "/home",
        name: "home",
        component: () =>
            import ('../page/index/home.vue'),
        children: [{
            path: "/index",
            name: "index",
            component: () =>
                import ('../page/index/index.vue')
        }, {
            path: "/read",
            name: "read",
            component: () =>
                import ('../page/read/read.vue')
        }, {
            path: "/unread",
            name: "unread",
            component: () =>
                import ('../page/unread/unread.vue')
        }, {
            path: "/product",
            name: "product",
            component: () =>
                import ('../page/product/product.vue')
        }, {
            path: "/InvestmentPolicy",
            name: "InvestmentPolicy",
            component: () =>
                import ('../page/InvestmentPolicy/index.vue')
        }, {
            path: "/firmMessage",
            name: "firmMessage",
            component: () =>
                import ('../page/firmMessage/firmMessage.vue')
        }, {
            path: "/CorporatePhotoAlbum",
            name: "CorporatePhotoAlbum",
            component: () =>
                import ('../page/CorporatePhotoAlbum/index.vue')
        }, {
            path: "/pressCenter",
            name: "pressCenter",
            component: () =>
                import ('../page/pressCenter/pressCenter.vue')
        }, {
            path: "/messageCenter",
            name: "messageCenter",
            component: () =>
                import ('../page/messageCenter/index.vue')
        }]
    }]
})
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});
export default router