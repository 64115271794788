<template>
  <div id="app">
    <router-view/>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}
</style>
